<template>
  <!-- 租车历史调度 -->
  <div ref="history" class="history">
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">订单编号：</div>
        <div class="col-2">
          <el-input
            v-model="queryForm.id"
            clearable
            placeholder="订单编号"
          ></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">下单人手机：</div>
        <div class="col-2 col-2-a">
          <el-input
            v-model="queryForm.ordererPhone"
            clearable
            placeholder="下单人手机"
          ></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">驾驶员：</div>
        <div class="col-2 col-2-a">
          <el-input
            v-model="queryForm.rentalDriverName"
            clearable
            placeholder="驾驶员姓名"
          ></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">驾驶员手机：</div>
        <div class="col-2 col-2-a">
          <el-input
            v-model="queryForm.rentalDriverPhone"
            clearable
            placeholder="驾驶员手机"
          ></el-input>
        </div>
      </div>

      <div class="actionBar-box">
        <div class="col-1 col-1-a">订单状态：</div>
        <div class="col-2 col-2-a">
          <el-select
            v-model="queryForm.orderStatus"
            clearable
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="actionBar-box">
        <div class="col-1">下单时间起：</div>
        <div class="col-2">
          <el-date-picker
            v-model="queryForm.createTimeStart"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="下单时间起"
            clearable
          ></el-date-picker>
        </div>
      </div>

      <div class="actionBar-box">
        <div class="col-1">下单时间止：</div>
        <div class="col-2">
          <el-date-picker
            v-model="queryForm.createTimeEnd"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="下单时间止"
            clearable
          ></el-date-picker>
        </div>
      </div>

      <div class="actionBar-box">
        <div class="col-1">取车时间起：</div>
        <div class="col-2">
          <el-date-picker
            v-model="queryForm.getVehicleTimeStart"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="取车时间起"
          ></el-date-picker>
        </div>
      </div>

      <div class="actionBar-box">
        <div class="col-1">取车时间止：</div>
        <div class="col-2">
          <el-date-picker
            v-model="queryForm.getVehicleTimeEnd"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="取车时间止"
          ></el-date-picker>
        </div>
      </div>

      <div class="actionBar-box">
        <div class="col-1">还车时间起：</div>
        <div class="col-2">
          <el-date-picker
            v-model="queryForm.returnVehicleTimeStart"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="还车时间起"
          ></el-date-picker>
        </div>
      </div>

      <div class="actionBar-box">
        <div class="col-1">还车时间止：</div>
        <div class="col-2">
          <el-date-picker
            v-model="queryForm.returnVehicleTimeEnd"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="还车时间止"
          ></el-date-picker>
        </div>
      </div>

      <div class="actionBar-box">
        <div class="col-1">租车车型：</div>
        <div class="col-2">
          <el-input
            v-model="queryForm.rentalModelName"
            clearable
            placeholder="租车车型名称"
          ></el-input>
          <!-- <el-select clearable v-model="queryForm.charteredComboTemplateIds" placeholder="请选择">
            <el-option
              v-for="item in packageList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>-->
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">车型分类：</div>
        <div class="col-2">
          <el-select
            v-model="queryForm.charteredVehicleModelTypeIds"
            clearable
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in packageList"
              :key="item.id"
              :label="item.vehicleTypeChildName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">车商名称：</div>
        <div class="col-2">
          <el-select
            v-model="queryForm.carServiceProviderId"
            clearable
            placeholder="请选择"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in carDealerList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="queryFun"
          >查询</el-button
        >
        <el-button
          v-if="listFind('导出')"
          type="primary"
          size="small"
          @click="exportFun"
          >导出</el-button
        >
      </div>
    </div>
    <Table
      fixed="right"
      :col-class-type="true"
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :table-height="TableHeight"
      operation-width="120"
    >
      <template slot-scope="{ scopeRow }">
        <div style="text-align: center">
          <el-button type="text" size="small" @click="seeFun(scopeRow)"
            >详情</el-button
          >
          <el-button
            v-show="scopeRow.orderStatus === 20 ? true : false"
            type="text"
            size="small"
            @click="handleRefund(scopeRow)"
            >退订</el-button
          >
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <el-dialog
      v-if="show"
      :visible.sync="show"
      width="1200px"
      :title="headerText"
      @close="clearForm"
    >
      <div ref="ActionBar" class="ActionBar ActionBarPopup">
        <div class="actionBar-box">
          <div class="col-1">订单编号：</div>
          <div class="col-2">{{ rowItem.id }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">驾驶员：</div>
          <div class="col-2">{{ rowItem.rentalDriverName }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">驾驶员手机：</div>
          <div class="col-2">{{ rowItem.rentalDriverPhone }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">取车城市：</div>
          <div class="col-2">{{ rowItem.cityName }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">取车点：</div>
          <el-tooltip
            effect="dark"
            :content="rowItem.pickLocationName"
            placement="top-start"
            ><div class="col-2">{{ rowItem.pickLocationName }}</div></el-tooltip
          >
        </div>
        <div class="actionBar-box">
          <div class="col-1">取车时间：</div>
          <div class="col-2">{{ rowItem.getVehicleTime }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">还车城市：</div>
          <div class="col-2">{{ rowItem.returnLocationCityName }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">还车点：</div>
          <el-tooltip
            effect="dark"
            :content="rowItem.returnLocationName"
            placement="top-start"
            ><div class="col-2">
              {{ rowItem.returnLocationName }}
            </div></el-tooltip
          >
        </div>
        <div class="actionBar-box">
          <div class="col-1">还车时间：</div>
          <div class="col-2">{{ rowItem.returnVehicleTime }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">租车天数：</div>
          <div class="col-2">{{ rowItem.days }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">租车车型：</div>
          <div class="col-2">{{ rowItem.rentalModelName }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">车商名称：</div>
          <div class="col-2">{{ rowItem.carServiceProviderName }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">订单金额：</div>
          <div class="col-2">{{ rowItem.totalPrices }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">下单时间：</div>
          <div class="col-2">{{ rowItem.createTime }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">订单状态：</div>
          <div class="col-2">{{ rowItem.orderStatus | getStatusTxt }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">退订金额：</div>
          <div class="col-2">{{ rowItem.refundOrderPrices || 0 }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">退订手续费：</div>
          <div class="col-2">{{ rowItem.refundOrderServicePrices || 0 }}</div>
        </div>
        <div class="actionBar-box">
          <div class="col-1">退订时间：</div>
          <div class="col-2">{{ rowItem.refundAt }}</div>
        </div>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane v-if="rowItem.isSubmitLicence" label="审核记录" name="1">
          <div>
            <h2>证照信息</h2>
            <div class="content1">
              <div class="column">
                <div class="item">
                  <label>承租人姓名：</label>
                  <p>{{ rowItem.rentalDriverName }}</p>
                </div>
                <div class="item">
                  <label>身份证号：</label>
                  <p>{{ rowItem.idCard }}</p>
                </div>
                <div class="item">
                  <label>驾驶证号：</label>
                  <p>{{ rowItem.rentalDriverInfo.identifyIdCard }}</p>
                </div>
                <div class="item">
                  <label>初次领证日期：</label>
                  <p>{{ rowItem.rentalDriverInfo.drivingLicenceIssueTime }}</p>
                </div>
              </div>
              <div class="column">
                <div class="item">
                  <label>手机号码：</label>
                  <p>{{ rowItem.rentalDriverPhone }}</p>
                </div>
                <div class="item">
                  <label>有效期限：</label>
                  <p>
                    {{ rowItem.rentalDriverInfo.identifyIdCardStartTime }} 至
                    {{ rowItem.rentalDriverInfo.identifyIdCardNoEndTime }}
                  </p>
                </div>
                <div class="item">
                  <label>准驾车型：</label>
                  <p>{{ rowItem.rentalDriverInfo.drivingLicenceClass }}</p>
                </div>
                <div class="item">
                  <label>有效期限：</label>
                  <p>
                    {{ rowItem.rentalDriverInfo.drivingLicenceStartTime }} 至
                    {{ rowItem.rentalDriverInfo.drivingLicenceEndTime }}
                  </p>
                </div>
              </div>
              <div class="images">
                <div class="photo">
                  <el-image
                    v-for="(item, index) in photoList"
                    :key="index"
                    :src="item"
                    style="width: 150px; height: 100px"
                    :preview-src-list="srcList"
                    @click="clickPhoto(item)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2>审核信息</h2>
            <div class="content2">
              <div class="row">
                <div class="item">
                  <label>审核结果：</label>
                  <p>{{ rowItem.licenceStatus }}</p>
                </div>
                <div class="item">
                  <label>审核人：</label>
                  <p>{{ rowItem.licenceOperatorName }}</p>
                </div>
                <div class="item">
                  <label>审核时间：</label>
                  <p>{{ rowItem.licenceTime }}</p>
                </div>
              </div>
              <div class="row">
                <label style="margin-top: 8px">审核意见：</label>
                <el-input
                  v-model="rowItem.licenceRemark"
                  readonly
                  type="textarea"
                  :rows="3"
                ></el-input>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="取还车记录" name="2">
          <div class="logBox ActionBarPopup">
            <div style="width: 100%">
              <div class="actionBar-box">
                <div class="col-1">车辆：</div>
                <div v-if="rowItem.vehiclePlateNumber" class="col-2">
                  {{ rowItem.vehicleBrand }}-{{ rowItem.vehiclePlateNumber
                  }}{{ rowItem.vehiclePlateColor }}-{{
                    rowItem.seatNumber || 0
                  }}座
                </div>
                <div v-else class="col-2">无</div>
              </div>
            </div>
            <div class="actionBar-box">
              <div class="col-1">取车时间：</div>
              <div class="col-2">{{ rowItem.schedulingTime || "无" }}</div>
            </div>
            <div class="actionBar-box">
              <div class="col-1">取车时里程表值：</div>
              <div class="col-2">
                {{ rowItem.startDriveDistance || "无" }}
              </div>
            </div>
            <div class="actionBar-box">
              <div class="col-1">操作员：</div>
              <div class="col-2">{{ rowItem.schedulingUser || "无" }}</div>
            </div>
            <div class="actionBar-box">
              <div class="col-1">还车时间：</div>
              <div class="col-2">
                {{ rowItem.returnSchedulingTime || "无" }}
              </div>
            </div>
            <div class="actionBar-box">
              <div class="col-1">还车时里程表值：</div>
              <div class="col-2">{{ rowItem.endDriveDistance || "无" }}</div>
            </div>
            <div class="actionBar-box">
              <div class="col-1">操作员：</div>
              <div class="col-2">
                {{ rowItem.returnSchedulingUser || "无" }}
              </div>
            </div>
            <div class="actionBar-box">
              <div class="col-1">超时时长：</div>
              <div class="col-2">{{ getDiffTime || "无" }}</div>
            </div>
            <div class="actionBar-box">
              <div class="col-1">用车里程数：</div>
              <div class="col-2">{{ getMemter || "无" }}</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="rowItem.isPayDeposit" label="退押金记录" name="3">
          <div>
            <h2>租车押金</h2>
            <div class="content2">
              <div class="row">
                <div class="item">
                  <label>租车押金：</label>
                  <p>{{ rowItem.vehicleDeposit }}</p>
                </div>
                <div class="item">
                  <label>扣除金额：</label>
                  <p>{{ rowItem.vehicleDepositDeduct }}</p>
                </div>
                <div class="item">
                  <label>退还金额：</label>
                  <p>{{ rowItem.vehicleDepositRefund }}</p>
                </div>
              </div>
              <div class="row">
                <div class="item2">
                  <label style="margin-top: 8px">扣除原因：</label>
                  <el-input
                    v-model="rowItem.vehicleDepositDeductRemark"
                    readonly
                    type="textarea"
                    :rows="3"
                  ></el-input>
                </div>
                <div class="item3">
                  <label>操作员：</label>
                  <p>{{ rowItem.vehicleDepositDeductOperatorName }}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2>违章押金</h2>
            <div class="content2">
              <div class="row">
                <div class="item">
                  <label>违章押金：</label>
                  <p>{{ rowItem.violationDeposit }}</p>
                </div>
                <div class="item">
                  <label>扣除金额：</label>
                  <p>{{ rowItem.violationDepositDeduct }}</p>
                </div>
                <div class="item">
                  <label>退还金额：</label>
                  <p>{{ rowItem.violationDepositRefund }}</p>
                </div>
              </div>
              <div class="row">
                <div class="item2">
                  <label style="margin-top: 8px">扣除原因：</label>
                  <el-input
                    v-model="rowItem.violationDepositDeductRemark"
                    readonly
                    type="textarea"
                    :rows="3"
                  ></el-input>
                </div>
                <div class="item3">
                  <label>操作员：</label>
                  <p>{{ rowItem.violationDepositDeductOperatorName }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clearForm">关 闭</el-button>
      </div>
    </el-dialog>

    <refund-model ref="refundModel" @cancelFinsh="queryFun" />
  </div>
</template>

<script>
import { lastTime } from "@/tools/getTime.js";
import host from "@/api/static/host.js";
import {
  renderCarTypeListAPI,
  exportRentOrderAPI,
  searchRentTableAPI,
} from "@/api/lib/api.js";
const statusList = [
  { value: 10, label: "待支付" },
  { value: 20, label: "预订成功" },
  { value: 30, label: "已取车" },
  { value: 40, label: "已完成" },
  { value: 50, label: "用户已退订" },
  { value: 55, label: "退款中" },
  { value: 60, label: "后台已退订" },
  { value: 70, label: "订单取消" },
];
import RefundModel from "./takeCarModels/RefundModel";
import moment from "moment";
export default {
  components: {
    RefundModel,
  },
  data() {
    return {
      activeName: "1",
      TableHeight: 0,
      rowItem: {},
      show: false,
      headerText: "查看详情",
      total: 0,
      queryForm: {
        createTimeStart: "",
        createTimeEnd: "",
        pageSize: 10,
        currentPage: 1,
      },
      packageList: [], //套餐下拉数据
      carDealerList: [], //车商下拉框数据
      // 订单状态list
      orderStatusList: [...statusList],
      tableData: [],
      titleName: [
        {
          title: "订单编号",
          props: "id",
          width: 220,
        },
        {
          title: "下单人",
          props: "ordererPhone",
          width: 120,
        },
        // {
        //   title: "下单人手机",
        //   props: "ordererPhone",
        //   width: 120
        // },
        {
          title: "驾驶员姓名",
          props: "rentalDriverName",
          width: 120,
        },
        {
          title: "驾驶员身份证",
          props: "idCardNo",
          width: 120,
          SpecialJudgment: (res) => {
            return this.decryptDes(res).replace(
              /^(.{4})(?:\d+)(.{3})$/,
              "$1******$2"
            );
          },
        },
        {
          title: "驾驶员手机号",
          props: "rentalDriverPhone",
          width: 120,
        },
        {
          title: "取车城市",
          props: "cityName",
          width: 150,
        },
        {
          title: "取车点",
          props: "pickLocationName",
          width: 150,
        },
        {
          title: "取车时间",
          props: "getVehicleTime",
          width: 150,
        },
        {
          title: "还车城市",
          props: "returnLocationCityName",
          width: 150,
        },
        {
          title: "还车点",
          props: "returnLocationName",
          width: 150,
        },
        {
          title: "还车时间",
          props: "returnVehicleTime",
          width: 140,
        },
        {
          title: "租车天数",
          props: "days",
        },
        {
          title: "租车车型",
          props: "rentalModelName",
        },
        {
          title: "车型分类",
          props: "charteredVehicleModelTypeName",
        },
        {
          title: "车商名称",
          props: "carServiceProviderName",
          width: 160,
        },
        {
          title: "下单时间",
          props: "createTime",
          width: 170,
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            let ps = this.orderStatusList.find((item) => item.value == res);
            return ps ? ps.label : "";
          },
        },
        {
          title: "订单金额",
          props: "totalPrices",
        },
        {
          title: "退订金额",
          props: "refundOrderPrices",
        },
        {
          title: "退订手续费",
          props: "refundOrderServicePrices",
        },
        {
          title: "退订时间",
          props: "refundAt",
        },
        {
          title: "缴租车押金",
          props: "vehicleDeposit",
        },
        {
          title: "退租车押金",
          props: "vehicleDepositRefund",
        },
        {
          title: "租车押金扣除金额",
          props: "vehicleDepositDeduct",
        },
        {
          title: "租车押金扣除原因",
          props: "vehicleDepositDeductRemark",
          width: 150,
        },
        {
          title: "退租车押金时间",
          props: "vehicleDepositRefundTime",
          SpecialJudgment: (res) => (res ? res.slice(0, 16) : ""),
          width: 140,
        },
        {
          title: "缴违章押金",
          props: "violationDeposit",
        },
        {
          title: "退违章押金",
          props: "violationDepositRefund",
        },
        {
          title: "违章押金扣除金额",
          props: "violationDepositDeduct",
        },
        {
          title: "违章押金扣除原因",
          props: "violationDepositDeductRemark",
          width: 150,
        },
        {
          title: "退违章押金时间",
          props: "violationDepositRefundTime",
          SpecialJudgment: (res) => (res ? res.slice(0, 16) : ""),
          width: 140,
        },
      ],
      form: {},
      photoList: [],
      srcList: [],
    };
  },
  computed: {
    getDiffTime() {
      //取车时间
      let m1 = moment(this.rowItem.returnVehicleTime);
      //还车时间
      let m2 = moment(this.rowItem.returnSchedulingTime);

      let m = m2.diff(m1, "minute");
      if (!m) {
        return "无";
      }

      if (m <= 0) {
        return "0天0小时0分钟";
      }
      let d = 0,
        h = 0,
        mm = 0;
      mm = parseInt(m % 60);
      if (m >= 60) {
        h = parseInt(m / 60);
      }
      if (h >= 24) {
        d = parseInt(h / 24);
      }
      h = h - d * 24;
      return `${d}天${h}小时${mm}分钟`;
    },
    getMemter() {
      let start = parseInt(this.rowItem.startDriveDistance);
      let end = parseInt(this.rowItem.endDriveDistance);
      if (start && end && end >= start) {
        return end - start;
      }
      return 0;
    },
  },
  filters: {
    getStatusTxt(v) {
      let ps = statusList.find((item) => item.value === v);
      if (ps) {
        return ps.label;
      }
      return "";
    },
  },
  beforDestroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  mounted() {
    this.queryForm.createTimeStart = lastTime(new Date()) + " 00:00:00";
    let day = new Date();
    this.queryForm.createTimeEnd =
      day.getFullYear() +
      "-" +
      (day.getMonth() + 1) +
      "-" +
      day.getDate() +
      " 23:59:59";
    this.renderData();
    // 获取租车车型下拉数据
    renderCarTypeListAPI({ status: 0, businessType: 5 }).then((res) => {
      if (res.code == "SUCCESS") {
        this.packageList = res.data;
      }
    });
    // 获取车商下拉数据
    this.providerListFun(5).then((res) => {
      this.carDealerList = res;
    });
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    decryptDes(message, key = "inheritech_key") {
      var cryptoJs = require("crypto-js");
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const decrypted = cryptoJs.DES.decrypt(
        message, // 若message是base64格式，则无需转16进制hex，直接传入message即可
        keyHex,
        {
          mode: cryptoJs.mode.ECB,
          padding: cryptoJs.pad.Pkcs7,
        }
      );
      return decrypted.toString(cryptoJs.enc.Utf8);
    },
    //各种时间段选择
    changeTime(t, key) {
      let tagStart = [key] + "Start";
      let tagEnd = [key] + "End";
      if (t && t.length) {
        this.queryForm = Object.assign({}, this.queryForm, {
          [tagStart]: t[0],
          [tagEnd]: t[1],
        });
      } else {
        this.queryForm = Object.assign({}, this.queryForm, {
          [tagStart]: "",
          [tagEnd]: "",
        });
      }
    },

    // 点击查询按钮的时候
    queryFun() {
      this.queryForm.pageSize = 10;
      this.queryForm.currentPage = 1;
      this.renderData();
    },
    // 计算表格高度
    computeHeight() {
      const boxHeight = this.$refs["history"].clientHeight;
      const topHeight = this.$refs["ActionBar"].clientHeight;
      this.TableHeight = boxHeight - topHeight - 90;
    },
    clearForm() {
      this.show = false;
    },

    //退订
    handleRefund(row) {
      this.$refs.refundModel.show(row);
    },
    // 点击表格查看按钮
    seeFun(scope) {
      this.show = true;
      this.rowItem = { ...scope };
      if (scope.isSubmitLicence) {
        this.rowItem.idCard = this.decryptDes(scope.idCardNo).replace(
          /^(.{4})(?:\d+)(.{3})$/,
          "$1******$2"
        );
        this.rowItem.rentalDriverInfo.identifyIdCard = this.decryptDes(
          scope.rentalDriverInfo.identifyIdCardNo
        ).replace(/^(.{4})(?:\d+)(.{3})$/, "$1******$2");
        this.rowItem.rentalDriverInfo.drivingLicenceStartTime =
          scope.rentalDriverInfo.drivingLicenceStartTime.slice(0, 10);
        this.rowItem.rentalDriverInfo.drivingLicenceEndTime =
          scope.rentalDriverInfo.drivingLicenceEndTime.slice(0, 10);
        this.rowItem.rentalDriverInfo.drivingLicenceIssueTime =
          scope.rentalDriverInfo.drivingLicenceIssueTime.slice(0, 10);
        this.rowItem.rentalDriverInfo.identifyIdCardStartTime =
          scope.rentalDriverInfo.identifyIdCardStartTime.slice(0, 10);
        this.rowItem.rentalDriverInfo.identifyIdCardNoEndTime =
          scope.rentalDriverInfo.identifyIdCardNoEndTime.slice(0, 10);
        scope.licenceTime &&
          (this.rowItem.licenceTime = scope.licenceTime.slice(0, 16));
        this.rowItem.licenceStatus =
          this.rowItem.licenceStatus === 1
            ? "审核通过"
            : this.rowItem.licenceStatus === 0
            ? "审核不通过"
            : "";
        this.photoList = [
          host.imgURL + scope.rentalDriverInfo.identifyIdCardNoFrontPhoto,
          host.imgURL + scope.rentalDriverInfo.identifyIdCardNoReversePhoto,
          host.imgURL + scope.rentalDriverInfo.drivingLicenceFrontPhoto,
          host.imgURL + scope.rentalDriverInfo.drivingLicenceReversePhoto,
        ];
        this.activeName = "1";
      } else {
        this.activeName = "2";
      }
    },
    // 导出方法
    exportFun() {
      let exportForm = { ...this.queryForm };
      exportRentOrderAPI(exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "租车订单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 表格数据渲染
    renderData() {
      searchRentTableAPI(this.queryForm).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.queryForm.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.currentPage = 1;
      this.renderData();
    },
    clickPhoto(val) {
      this.srcList = [val];
    },
  },
};
</script>

<style scoped lang="scss">
.el-tabs {
  padding: 10px;
}
.pagination {
  padding-right: 10px;
}
.ActionBarPopup {
  margin-bottom: 0 !important;
  background: #ffffff !important;
  .actionBar-box:nth-child(4n) {
    margin-right: 7px !important;
  }
  .actionBar-box:nth-child(3n) {
    margin-right: 0 !important;
  }
  .actionBar-box {
    width: calc(33.3% - 18px) !important;
    display: flex;
  }
  .col-1-a {
    width: 85px !important;
    text-align: right;
  }
  .col-1 {
    width: 85px !important;
    text-align: right;
  }
  .col-2 {
    border: 1px solid #d7d7d7;
    width: calc(100% - 150px) !important;
    border-radius: 4px;
    height: 100%;
    padding: 0 10px;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.ActionBar {
  background: #f7f8f9;
  padding: 16px 0 0 16px;
  padding-bottom: 0;
  display: flex;
  margin-bottom: 16px;
  flex-flow: wrap;
  width: calc(100% - 36px);
  margin-left: 10px;
  // height: 192px;
  .actionBar-box:nth-child(4n) {
    margin-right: 0;
  }
  .actionBar-box {
    width: calc(25% - 18px);
    height: 32px;
    line-height: 32px;
    margin-right: 7px;
    margin-bottom: 16px;
    padding-right: 10px;
    .col-1-a {
      width: 90px !important;
    }
    .col-1 {
      width: 90px;
      display: inline-block;
    }
    .col-2-a {
      width: calc(100% - 110px) !important;
    }
    .col-2 {
      display: inline-block;
      width: calc(100% - 110px);
      .el-select {
        width: 100%;
      }
    }
  }
}
.logBox {
  background: #fff;
  padding: 16px 0 0 16px;
  padding-bottom: 0;
  display: flex;
  margin-bottom: 16px;
  flex-flow: wrap;
  width: calc(100% - 36px);
  margin-left: 10px;
  // height: 192px;
  .actionBar-box:nth-child(4n) {
    margin-right: 0;
  }
  .actionBar-box {
    width: calc(25% - 18px);
    height: 32px;
    line-height: 32px;
    margin-right: 7px;
    margin-bottom: 16px;
    padding-right: 10px;
    .col-1-a {
      width: 90px !important;
    }
    .col-1 {
      width: 110px !important;
      display: inline-block;
      text-align: right;
    }
    .col-2-a {
      width: calc(100% - 135px) !important;
    }
    .col-2 {
      display: inline-block;
      width: calc(100% - 135px);
      .el-select {
        width: 100%;
      }
    }
  }
}
.ActionBar {
  .labelMax {
    transform: translateX(-10px);
    .col-1 {
      width: 90px !important;
    }
  }
}
.logBox {
  .actionBar-box {
    .col-1 {
      width: 120px !important;
    }
    .col-2 {
      width: calc(100% - 156px) !important;
    }
  }
}
.history {
  height: calc(100% - 10px);
  background: #ffffff;
  padding-top: 10px;
  .Table {
    margin-left: 10px;
    width: calc(100% - 20px);
  }
  .content1 {
    display: flex;
    .column {
      flex: 1;
      .item {
        font-size: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        label {
          width: 105px;
          flex-shrink: 0;
          text-align: right;
        }
        p {
          width: 200px;
          height: 34px;
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          line-height: 34px;
          padding-left: 10px;
        }
      }
    }
    .images {
      flex: 1;
      .photo {
        width: 100%;
        height: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(2, 50%);
      }
    }
  }
  .content2 {
    .row {
      display: flex;
      font-size: 15px;
      label {
        width: 105px;
        flex-shrink: 0;
        text-align: right;
      }
      p {
        height: 34px;
        width: 200px;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        line-height: 34px;
        padding-left: 10px;
      }
      .item {
        flex: 1;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      .item2 {
        width: 61%;
        display: flex;
      }
      .item3 {
        flex: 1;
        align-items: center;
        display: flex;
        margin-left: 65px;
      }
    }
  }
}
</style>
